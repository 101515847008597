import React, { useCallback, useState } from 'react';
import './TopButtons.css'
import { useNavigate, useLocation } from 'react-router-dom';
import { STUDIO_STACK } from '../utils/screenNames';
import PopUpWithMsg from './PopUpWithMsg';
import { clearSessionDesigns } from '../redux/sessionDesignSlice';
import { useDispatch } from 'react-redux';

function TopButtons ({selections, showSavedDesigns}) {

    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    let isComputer = window.innerWidth > 800;

    const [logOutPopUp, setLogOutPopUp] = useState(false)

    const goToCart = useCallback(() => {
        navigate(STUDIO_STACK.CART)
      }, [navigate])
    
    const goToMyDesigns = useCallback(() => {
        navigate(STUDIO_STACK.SAVED_DESIGNS, {state: {selections: selections, from: location.pathname} })
    }, [navigate, location, selections])

    const handleLogOutPress = useCallback(() => {
        setLogOutPopUp(true)
    }, [setLogOutPopUp])

    const logOut = useCallback(() => {
        window.localStorage.setItem("username", "")
        window.localStorage.setItem("access", "")
        window.localStorage.setItem("refresh", "")
        dispatch(clearSessionDesigns())
        navigate("/")
    }, [dispatch, navigate])


    return (
        <div className={`top-buttons ${!showSavedDesigns ? "selection " + ( !isComputer ? "bottom-center" : "") : ""}`}>
            <button className="top-button" onClick={goToCart}>
                GO TO CART
            </button>  
            {showSavedDesigns ?
            <button className="top-button" onClick={goToMyDesigns}>
                MY DESIGNS
            </button> : 
            <button className="top-button" onClick={handleLogOutPress}>
                LOG OUT
            </button> }

            {logOutPopUp && <PopUpWithMsg 
                optionText={"LOG OUT"}
                onOptionPress={logOut}
                popUpMsg={"Are you sure you want to log out?"}
                closePopUp={()=>{setLogOutPopUp(false)}}
                />}
        </div>
    )

}

export default TopButtons