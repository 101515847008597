import './BottomButtonsMobile.css'
import { useNavigate, useLocation } from 'react-router-dom';
import { STUDIO_STACK } from '../utils/screenNames';
import React, { useCallback } from 'react';


function BottomButtonsMobile ({selections}) {

    const navigate = useNavigate()
    const location = useLocation()

    const goToCart = useCallback(() => {
        navigate(STUDIO_STACK.CART)
      }, [navigate])
    
    const goToMyDesigns = useCallback(() => {
        navigate(STUDIO_STACK.SAVED_DESIGNS, {state: {selections: selections, from: location.pathname} })
    }, [navigate, location, selections])


    return (
        <div className='mt-4 bottom-buttons-mobile'>
            <button className="bottom-button-mobile" onClick={goToCart}>
                Go To Cart
            </button>  
            <button className="bottom-button-mobile" onClick={goToMyDesigns}>
                My Designs
            </button> 
        </div>
    )

}

export default BottomButtonsMobile