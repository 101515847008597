import React from 'react';
import './SavedItemOptions.css';
import { Button } from 'reactstrap';
import { FaTrash } from "react-icons/fa6";

function SavedItemOptions({edit, addToCart, deleteItem, index}) {
    return (
        <div className='item-options'>
            <Button className="saved-item-button" onClick={() => edit(index)}>
                {"EDIT"}
            </Button> 
            <Button className="saved-item-button" onClick={() => addToCart(index)}>
                {"ADD TO CART"}
            </Button>      

            <FaTrash onClick={()=>deleteItem(index)} className='saved-item-delete'/>
        </div>
    );
}

export default SavedItemOptions;
