export const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

export const validatePassword = (password) => {
    const minLength = 8;
    const hasNumbers = /[0-9]/.test(password);
    const hasLetters = /[a-zA-Z]/.test(password);
    const noSpaces = !/\s/.test(password);

    if (password.length < minLength) {
      return 'Password must be at least 8 characters long.';
    }
    if (!hasNumbers) {
      return 'Password must contain at least one number.';
    }
    if (!hasLetters) {
      return 'Password must contain at least one letter.';
    }
    if (!noSpaces) {
      return 'Password must not contain spaces.';
    }
    return '';
};

export function isTokenExpired(expiryDate) {
  const expiry = new Date(expiryDate);
  const now = new Date();
  
  return now > expiry;
}
