import { combineReducers } from '@reduxjs/toolkit';
import cartReducer from './cartSlice';
import sessionDesignReducer from './sessionDesignSlice'
import designsReducer from './designsSlice'

const rootReducer = combineReducers({
    cart: cartReducer,
    sessionDesign: sessionDesignReducer,
    designs: designsReducer
});

export default rootReducer;
