import React, { useState, useCallback } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { updateSizesAtIndex } from '../redux/cartSlice'
import PopUpOption from './PopUpOption';
import './SizePopUp.css'
import { prices } from '../utils/prices';

function SizePopUp({item, index}) {
    const dispatch = useDispatch()
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const sizes = item?.sizes
    const garment = item.item?.selections?.garment.toLowerCase()
    const type = item.item?.selections?.item?.toLowerCase()?.replace(/ /g, '_')
    const [childItemIndices, setChildItemIndices] = useState([])

    const showPriceInfo = false

    const setCartItemSizeDetails = (item, option, value, itemIndex, subItemIndex) => {
        const temp = sizes.slice()
        temp[subItemIndex] = {...item, [option]: value}

        if(option==="type"){
            if (value==="Kids"){
                !childItemIndices.includes(subItemIndex) && setChildItemIndices([...childItemIndices, subItemIndex])
            } 
            else{
                childItemIndices.includes(subItemIndex) && setChildItemIndices(childItemIndices.filter(index => index!==subItemIndex))
            }
        }

        dispatch(updateSizesAtIndex({index: itemIndex, sizes: temp}))
    }

    const getPrice = useCallback((garment, subItemIndex) => {
        const type = childItemIndices.includes(subItemIndex) ? 'child' : 'adult'

        const price = prices[garment][type]

        return price

    }, [childItemIndices])


    return (
        <>
            <Button className="add-info-button" onClick={toggle}>
                Add size and player info
            </Button>

            <Modal isOpen={modal} toggle={toggle} backdrop={true} fade={false} centered={true} scrollable={true}>
                <ModalHeader className='size-pop-up-bg' toggle={toggle}>Players info and sizes</ModalHeader>
                <ModalBody className='size-pop-up-bg'>
                    {
                        sizes.map((item, ind) => {
                            return (
                                <div key={index +"_"+ind}>
                                    <div className='sub-item-heading'>
                                        <p><strong>Player {ind+1}</strong></p>
                                        {showPriceInfo && <p>{`Price: £ ${getPrice(garment, ind)} ${!childItemIndices.includes(ind) ? "+ VAT" : ""}`}</p>}
                                    </div>

                                    {(garment==='shirts' || garment==='shorts') && type!=='training_kits' &&
                                        <Input type='number' onChange={(event)=>{setCartItemSizeDetails(item, "jersey", event.target.value, index, ind)}} placeholder='Jersey Number' value={item.jersey} className='mb-2'></Input>}
                                    
                                    {garment==='shirts' && type!=='training_kits' &&
                                        <Input type='text' onChange={(event)=>{setCartItemSizeDetails(item, "name", event.target.value, index, ind)}} placeholder='Player Name' value={item.name} className='mb-2'></Input>}
                                    
                                    <Input type="select" className='mb-2' value={item.type} placeholder='type' onChange={(event)=>{setCartItemSizeDetails(item, "type", event.target.value, index, ind)}}>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Kids">Kids</option>
                                    </Input>

                                    {childItemIndices.includes(ind) ?  
                                    
                                        <Input type="select" className='mb-2' value={item.size} placeholder='size' onChange={(event)=>{setCartItemSizeDetails(item, "size", event.target.value, index, ind)}}>
                                            <option value="XSY">XSY</option>
                                            <option value="SY">SY</option>
                                            <option value="MY">MY</option>
                                            <option value="LY">LY</option>
                                            <option value="XS">XS</option>
                                            <option value="S">S</option>
                                            <option value="M">M</option>
                                            <option value="L">L</option>
                                            <option value="XL">XL</option>
                                            <option value="2XL">2XL</option>
                                            <option value="3XL">3XL</option>

                                        </Input>

                                        : <Input type="select" className='mb-2' value={item.size}  onChange={(event)=>{setCartItemSizeDetails(item, "size", event.target.value, index, ind)}}>
                                            <option value="XXS">XXS</option>
                                            <option value="XS">XS</option>
                                            <option value="S">S</option>
                                            <option value="M">M</option>
                                            <option value="L">L</option>
                                            <option value="XL">XL</option>
                                            <option value="2XL">2XL</option>
                                            <option value="3XL">3XL</option>
                                            <option value="4XL">4XL</option>
                                        </Input>}
                                    <hr/>
                                </div>
                            )
                        })
                    }
                </ModalBody>
                
                <ModalFooter className='size-pop-up-bg'>
                    <PopUpOption minWidth='150px' text={'Close'} click={()=>toggle()}/>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default SizePopUp;