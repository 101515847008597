import { createSlice } from '@reduxjs/toolkit';

const getSizeDetails = (name="", jersey="", type="Male", size="M", cost=0) => {
    return JSON.parse(JSON.stringify({ name, jersey, type, size, cost }))
}

const initialState = {
  cart: [],
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToCart: (state, action) => {
            const data = action.payload
            let size = getSizeDetails()

            if(data.selections.garment === "Shirts"){
                const texts = data.selectedInputOptions.texts
                if(texts && texts.length === 2){
                    size = getSizeDetails(texts[1].value, texts[0].value, "M")
                }else if(texts && texts.length === 1){
                    size = getSizeDetails("", texts[0].value, "M")
                }else{
                    size = getSizeDetails("", "", "M")
                }
            }

            state.cart.push({
                item: action.payload, 
                quantity: 1, 
                sizes: [size]
            });
        },

        updateCartItem: (state, action) => {
            state.cart[action.payload.index].item = action.payload.item
        },
        increaseCartItemQunatity: (state, action) => {
            state.cart[action.payload.index].quantity++
            state.cart[action.payload.index].sizes.push(getSizeDetails())
        },
        decreaseCartItemQunatity: (state, action) => {
            if(state.cart[action.payload.index].quantity > 1){
                state.cart[action.payload.index].quantity--
                state.cart[action.payload.index].sizes.pop()
            }
        },
        addCartItemSizeDetails: (state, action) => {
            state.cart[action.payload.index].sizes.push(getSizeDetails())
        },
        updateCartItemSizeDetails: (state, action) => {
            state.cart[action.payload.index].sizes[action.payload.sizeIndex] = action.payload.size
        },
        updateSizesAtIndex: (state, action) => {
            state.cart[action.payload.index].sizes = action.payload.sizes
        },
        removeAtIndex: (state, action) => {
            state.cart.splice(action.payload.index, 1)
        },
        clearCart: (state) => {
            state.cart = [];
        },

        updateCartCost: (state, action) => {
            state.cart[action.payload.index].cost = action.payload.cost
        }
    },
});

export const { 
    addToCart,
    updateCartItem,
    increaseCartItemQunatity,
    decreaseCartItemQunatity,
    addCartItemSizeDetails,
    updateCartItemSizeDetails,
    updateSizesAtIndex,
    removeAtIndex,
    clearCart, 
    updateCartCost
} = cartSlice.actions;

export const getCart = (state) => state.cart.cart;

export default cartSlice.reducer;
