function hexToRgb(hex) {
    hex = hex.replace(/^#/, '');
    
    let r = parseInt(hex.slice(0, 2), 16);
    let g = parseInt(hex.slice(2, 4), 16);
    let b = parseInt(hex.slice(4, 6), 16);
    
    return [r, g, b];
}

function getScaledXyhw(x_crr, y_crr, height, width, max_dim)
{
    let sf = 1
    let scaled_height = height
    let scaled_width = width
    let x = 0
    let y = 0

    if(height>width)
    {
        sf = max_dim/height
    }
    
    else
    {
        sf = max_dim/width
    }

    scaled_height = height*sf
    scaled_width = width*sf
    x = (x_crr + max_dim/2) - (scaled_width/2)
    y = (y_crr + max_dim/2) - (scaled_height/2)
    return [x, y, scaled_width, scaled_height] 
}

function resizeToFitFront(x_crr, height, width, max_height, max_width)
{   
    let sf = 1
    let scaled_height = height
    let scaled_width = width
    let x = 0

    if(height>=width)
    {
        sf = max_height/height
    }
    
    else
    {
        sf = max_width/width
    }

    scaled_height = height*sf
    scaled_width = width*sf
    x = x_crr + Math.floor((max_width - scaled_width)/2)

    return [x, scaled_width, scaled_height] 
}



module.exports.getScaledXyhw = getScaledXyhw
module.exports.hexToRgb = hexToRgb
module.exports.resizeToFitFront = resizeToFitFront