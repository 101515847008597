import React, {useEffect, useState} from 'react'
import clubbieLogo from '../../assets/images/clubbie_logo_full.svg'
import './Landing.css'
import { STUDIO_STACK, AUTHENTICATION_STACK } from '../../utils/screenNames'
import { useNavigate } from 'react-router-dom';
import { isTokenExpired } from '../../utils/helpers';
import { refreshTokens } from '../../lib/authActions';
import OverlayLoader from '../../components/OverlayLoader';

function Landing() {

  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  
  const screenWidth = window.innerWidth;
  const isComputer = screenWidth > 800 


  const handleRegister = () => {
    navigate(AUTHENTICATION_STACK.REGISTER_OPTIONS)
  }

  const handleLogin = () => {
    navigate(AUTHENTICATION_STACK.LOGIN_OPTIONS)
  }

  const handleGoToStudio = () => {
    navigate(STUDIO_STACK.DESIGN_STUDIO)
  }

  useEffect(() => { 
    
    const accessString = window.localStorage.getItem("access")
    const refreshString = window.localStorage.getItem("refresh")

    if(accessString) {
      const access = JSON.parse(accessString)
      if(isTokenExpired(access.expiry)){
        const refresh = JSON.parse(refreshString)

        const handleResetTokens = async (refreshToken) => {
          function onSuccess (data) {
            const {access, refresh} = data
            window.localStorage.setItem("access", JSON.stringify(access))
            window.localStorage.setItem("refresh", JSON.stringify(refresh))
            navigate(STUDIO_STACK.SELECTION_PAGE)
          }
      
          function onFail () {
            setIsLoading(false)
          }
      
          await refreshTokens(refreshToken, onSuccess, onFail)
        }

        handleResetTokens(refresh)
      }
      else{
        navigate(STUDIO_STACK.SELECTION_PAGE)
      }
    }

    else {
      setIsLoading(false)
    }

  }, [navigate])

  return (
    <>
      <video id='background-video' autoPlay muted loop playsInline>
        <source src={`videos/bg-vid${!isComputer ? "-mobile.mp4" : ".mov"}`} type="video/mp4" />
      </video>
    
    <div className='app-container-landing'>
      
      {isLoading ? <OverlayLoader/> :
      
        <div className="app-content">
          <div className='auth-top-bar-first-page'>
            <img className="clubbie-logo" src={clubbieLogo} alt="hello"/>
          </div>

          <div className='welcome-text-box'>
            <p className="welcome-text">Welcome to Clubbie's Teamwear Design Studio.</p>
            <p className="welcome-msg-landing"> For the best experience and to link your designs to your Clubbie profile in the app, 
                                        sign in or create an account now
            </p>
          </div>

          <div className='button-container-first-page'>
            <button className='register-button' onClick={handleRegister}>
              I'm new to Clubbie
            </button>
            <button className='login-button' onClick={handleLogin}>
              I already have an account
            </button>
          </div>

            <button className='studio-button' onClick={handleGoToStudio}>
              Skip & go straight to studio
            </button>
        </div> }
    </div>
  </>

  )
}


export default Landing