import axios from "axios";
import { config } from "../utils/config";


export async function googleLoginRequest(idToken, onSuccess, onFail) {

    try {
        const data = await axios({
            method: 'post',
            url: `${config.API_BASE_URL}/auth/login/google`,
            data: {id_token: idToken},
            encoding: null
        })

        if (typeof onSuccess === 'function') onSuccess(data.data)
    }

    catch (err) {
        if (typeof onFail === 'function') onFail()
    }

}

export async function appleLoginRequest(appleSignInResponse, onSuccess, onFail) {
    try {
        const data = await axios({
            method: 'post',
            url: `${config.API_BASE_URL}/auth/login/apple`,
            data: appleSignInResponse,
            encoding: null
        })

        if (typeof onSuccess === 'function') onSuccess(data.data)
    }

    catch (err) {
        if (typeof onFail === 'function') onFail()
    }

}

export async function registration(registrationInfo, onSuccess, onFail) {
    try {

        const {fullName, email, telephone, password} = registrationInfo

        const body = {
            fullName,
            ...(email && { email }),
            ...(telephone && { telephone }),
            password,
          };

        const data = await axios({
            method: 'post',
            url: `${config.API_BASE_URL}/auth/signup`,
            data: body,
            encoding: null
        })

        if (typeof onSuccess === 'function') onSuccess(data.data, registrationInfo)
    }

    catch (err) {
        if (typeof onFail === 'function') onFail(err?.response?.status, registrationInfo)
    }
}

export async function verifyAccount (confirmationCode, user, onSuccess, onFail) {
    try {
        const accessToken = user.tokens.access.token
        const headers = {
            'Authorization': `Bearer ${accessToken}`
        }

        const data = await axios({
            method: 'patch',
            url: `${config.API_BASE_URL}/auth/activate-account`,
            headers: headers,
            data: {activationCode: confirmationCode},
            encoding: null
        })  

        const resp = {user: data.data?.user, 
                      tokens: {access: user.tokens.access, 
                               refresh: user.tokens.refresh}}

        if (typeof onSuccess === 'function') onSuccess(resp)
    }

    catch (err) {
        if (typeof onFail === 'function') onFail(err)
    }
}

export async function activateTemporaryAcc (confirmationCode, registrationInfo, onSuccess, onFail){
    
    try {
        const data = await axios({
            method: 'post',
            url: `${config.API_BASE_URL}/auth/activate-temporary-account`,
            data: {activationCode: confirmationCode, 
                ...(registrationInfo.email? {email: registrationInfo.email} : {telephone: registrationInfo.telephone})},
            encoding: null
        })

        if (typeof onSuccess === 'function') onSuccess(data.data)
    }

    catch (err) {
        if (typeof onFail === 'function') onFail(err)
    }
}

export async function resendVerificationCode(registrationInfo, user, onSuccess, onFail) {
    try{
        const {email, telephone} = registrationInfo
        
        if (email || telephone) {
            const body = {
            ...(email && { email }),
            ...(telephone && { telephone }),
            }

            const accessToken = user.tokens.access.token
            const headers = {
                'Authorization': `Bearer ${accessToken}`
            }

            await axios({
                method: 'post',
                url: `${config.API_BASE_URL}/auth/resend-activate-account`,
                data: body,
                headers: headers,
                encoding: null
            }) 

            if (typeof onSuccess === 'function') onSuccess()
        }
    }
    catch (err){
        if (typeof onFail === 'function') onFail()

    }
}

export async function loginWithEmailPhone (email, telephone, password, onSuccess, onFail) {
    try {

        const body = {
            ...(email && { email }),
            ...(telephone && { telephone }),
            password,
          };

        const data = await axios({
            method: 'post',
            url: `${config.API_BASE_URL}/auth/login`,
            data: body,
            encoding: null
        })

        if (typeof onSuccess === 'function') onSuccess(data.data)
    }

    catch (err) {
        if (typeof onFail === 'function') onFail(err?.response?.data?.message)
    }
}

export async function forgotPassword(email, telephone, onSuccess, onFail) {
    try {

        const body = {
            ...(email && { email }),
            ...(telephone && { telephone }),
          };

        await axios({
            method: 'post',
            url: `${config.API_BASE_URL}/auth/forgot-password`,
            data: body,
            encoding: null
        })

        if (typeof onSuccess === 'function') onSuccess()
    }

    catch (err) {
        if (typeof onFail === 'function') onFail()
    }

}

export async function resetAccountPassword(email, telephone, password, resetPasswordCode, onSuccess, onFail){
    try {

        const body = {
            ...(email && { email }),
            ...(telephone && { telephone }),
            password, 
            resetPasswordCode
          };

        await axios({
            method: 'patch',
            url: `${config.API_BASE_URL}/auth/reset-password`,
            data: body,
            encoding: null
        })

        if (typeof onSuccess === 'function') onSuccess()
    }

    catch (err) {
        if (typeof onFail === 'function') onFail()
    }
}

export async function refreshTokens(refresh, onSuccess, onFail){
    try {
        const refreshToken = refresh.token

        const data = await axios({
            method: 'post',
            url: `${config.API_BASE_URL}/auth/refresh-tokens`,
            data: {refreshToken: refreshToken},
            encoding: null
        })

        if (typeof onSuccess === 'function') onSuccess(data.data)
    }

    catch (err) {
        if (typeof onFail === 'function') onFail()
    }
}