import React, {useState} from 'react'
import clubbieLogo from '../../assets/images/clubbie_logo_full.svg'
import backButton from '../../assets/images/back_button.svg'
import { AUTHENTICATION_STACK } from '../../utils/screenNames'
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import './RegisterWithEmail.css';
import {Input} from 'reactstrap';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { isValidEmail, validatePassword } from '../../utils/helpers';
import { registration } from '../../lib/authActions';

function RegisterWithEmail() {

  const navigate = useNavigate()
  const [registrationFailed, setRegistrationFailed] = useState(false)
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false);

  const [warningMsg, setWarningMsg] = useState("")

  const registerWithEmail = async ()=> {
    function onSuccess(userData) {
        navigate(AUTHENTICATION_STACK.VERIFICATION, {state: {user: userData, registrationInfo: {fullName, email, password} }})
    }

    function onFail(errType, registrationInfo) {
      if(errType===409){
        navigate(AUTHENTICATION_STACK.VERIFICATION, {state: {user: null, registrationInfo: registrationInfo }})
      }
      else{
        setRegistrationFailed(true)
      }
    }
    
    if (!isValidEmail(email)) {
        setWarningMsg("Please provide a valid email address")
    }
    else {
        const passwordErr = validatePassword(password)
        if(passwordErr){
            setWarningMsg(passwordErr)
        }
        else if (!fullName){
            setWarningMsg("Please provide your full name")
        }

        else{
            const info = {fullName, email, password}
            setWarningMsg("")
            await registration(info, onSuccess, onFail)
        }
    }

  }

  const goToLogin = () => {
    navigate(AUTHENTICATION_STACK.LOGIN_OPTIONS)
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const goBack = () => {
    navigate(-1)
  }

  const goToPrivacyPolicy = () => {
    navigate(AUTHENTICATION_STACK.PRIVACY_POLICY)
  }

  const goToTermsAndConditions = () => {
    navigate(AUTHENTICATION_STACK.TERMS_AND_CONDITIONS)
  }


  return (
    <div className="app-container" >

      <div className='auth-top-bar'>
        <img className='back-button' src={backButton} alt="hello" onClick={goBack}/> 
        <img className="clubbie-logo" src={clubbieLogo} alt="hello"/>
      </div>
      

      <div className='welcome-text-box-email-register'>
        <p className="welcome-text">Register with Clubbie</p>
        <p className="welcome-msg"> Create your account.</p>
      </div>


      {registrationFailed && <div>
                            <p className="login-failed-msg">Registration failed.</p>
                        </div>}

      <div className='button-container-email-register'>

        <Input required type='text' onChange={(event)=>{setFullName(event.target.value)}} 
                placeholder='Full name' value={fullName} className='mb-4 email-input' ></Input>

        <Input required type='email' onChange={(event)=>{setEmail(event.target.value)}} 
                placeholder='Email' value={email} className='mb-4 email-input' ></Input>

        <div className='password-input'>
          <Input
            type={showPassword ? "text" : "password"}
            name="password"
            id="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="mb-5 email-input"
          />
          <Button 
            type="button" 
            onClick={togglePasswordVisibility} 
            className="password-toggle-button"
          >
            {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          </Button>
        </div>

        <Button 
            className='register-button'
            onClick={registerWithEmail}>
            Register   
        </Button>

        {warningMsg && <p className='info-warning'>{warningMsg}</p>}


        <p className='go-login-msg'>
            Already have an account?
          <button className='go-login-button' onClick={goToLogin}>
            Log in.
          </button>
        </p>

        <p className='terms-msg'>
            By creating an account you accept our<br></br>
          <button className='terms-button' onClick={goToTermsAndConditions}>
            Terms and conditions.
          </button>
          Read our 
          <button className='terms-button' onClick={goToPrivacyPolicy}>
            Privacy Policy.
          </button>
        </p>

      </div>
  </div>
  )
}


export default RegisterWithEmail