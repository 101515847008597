import React, {useState} from 'react'
import clubbieLogo from '../../assets/images/clubbie_logo_full.svg'
import backButton from '../../assets/images/back_button.svg'
import {AUTHENTICATION_STACK } from '../../utils/screenNames'
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import './RegisterWithEmail.css';
import {Input} from 'reactstrap';
import { isValidEmail } from '../../utils/helpers';
import { forgotPassword } from '../../lib/authActions';
import OverlayLoader from '../../components/OverlayLoader';

function ForgotPasswordWithEmail() {

  const navigate = useNavigate()
  const [resetFailed, setResetFailed] = useState(false)
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [warningMsg, setWarningMsg] = useState("")

  const goBack = () => {
    navigate(-1)
  }

  const resetPassword = async () => {
    function onSuccess(){
        setIsLoading(false)
        navigate(AUTHENTICATION_STACK.RESET_PASSWORD_WITH_EMAIL, {state: {email: email}})
    }

    function onFail(){
        setIsLoading(false)
        setResetFailed(true)
    }

    if(resetFailed){
        setResetFailed(false)
    }

    if(isValidEmail(email)){
        setIsLoading(true)
        await forgotPassword(email, null, onSuccess, onFail)
    }
    else{
        setWarningMsg("please enter a valid email address")
    }


  }

  const goToLogin = () => {
    navigate(AUTHENTICATION_STACK.LOGIN_WITH_EMAIL)
  }


  return (
    <div className="app-container" >
      
      <div className='auth-top-bar'>
        <img className='back-button' src={backButton} alt="hello" onClick={goBack}/> 
        <img className="clubbie-logo" src={clubbieLogo} alt="hello"/>
      </div>

      <div className='welcome-text-box'>
        <p className="welcome-text">Reset your password</p>
        <p className="welcome-msg"> Enter the email address you used when creating an account with Clubbie.</p>
      </div>

      <div className='button-container-email-register'>

        {resetFailed && <p className='info-warning'>Password reset failed.</p>}

        <Input required type='email' onChange={(event)=>{setEmail(event.target.value)}} 
                placeholder='Email' value={email} className='mb-2 email-input' ></Input>

        {warningMsg && <p className='info-warning'>{warningMsg}</p>}
        
        <p className='go-register-msg'>
            Nevermind, I want to
          <button className='go-register-button' onClick={goToLogin}>
            Log in.
          </button>
        </p>

        <Button 
            className='register-button'
            onClick={resetPassword}>
            Reset Password   
        </Button>

        {isLoading && <OverlayLoader/>}

      </div>
  </div>
  )
}


export default ForgotPasswordWithEmail