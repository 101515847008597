import React, {useEffect, useState} from 'react';
import { Col, UncontrolledPopover, PopoverBody, Row, FormGroup, Label, Input } from 'reactstrap';
import './ColorPicker.css'
import DragDropFiles from './DragDropFiles';
import { colorPalette } from '../utils/studioSettings';
import textIcon from '../assets/images/text_icon.png'
import plusIcon from '../assets/images/plus_icon.png'
import remove from '../assets/images/remove.svg'
import { appColors } from '../utils/studioSettings';

function ColorPicker({option, index, showColor=true, showInputs=false, inputs, inputTitle, garment, type, prevInputs, updateInputs }){

    let defaultColor = option.default

    if(prevInputs.primary && inputTitle === 'primary'){
        defaultColor = prevInputs.primary
    }
    else if(prevInputs.secondary && inputTitle === 'secondary'){
        defaultColor = prevInputs.secondary
    }

    const [color, setColor] = useState(defaultColor);
    const [fields, setFields] = useState(null)
    const [activated, setActivated] = useState(false)

    const colors = colorPalette

    const updateColor = (value) => {
        const updatedFields = {...prevInputs, [inputTitle==='text' ? "textColor" : [inputTitle]]: value} 
        setFields(updatedFields)
        updateInputs(updatedFields)
        setColor(value)
    }

    const setLogo = (file, key, index) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          setValue(reader.result, key, index)
        };
        reader.onerror = function (error) {
        };
    }

    const setValue = (value, key, index) => {
        setActivated(true)
        const prevFields = prevInputs
        const object = {}
        object[key] = [
            ...prevFields[key].slice(0, index),
            {
                ...prevFields[key][index],
                value: value,
            },
            ...prevFields[key].slice(index+1), 
        ]
        const updatedFields =  {
            ...prevFields,
            ...object
        }

        updateInputs(updatedFields)
        setFields(updatedFields)
    }

    const handleRemoveImage = (key, index) => {
        setValue("", key, index)
    }

    useEffect(()=>{
        if(prevInputs && !activated){
            setFields(prevInputs)
        }
    }, [garment, prevInputs, activated])


    return (
            <div>
                { !(type==='training_kits' && index<2) &&
                <Row className='input-row'>
                    <Col md={8} className='p-4 input-heading-box'>
                        <p className='input-heading'>{option.text}</p>
                        <span className='input-text'>{index<2 ? 'Select color' : option.text === "Logos" ? 'Add logos' : 'Add text' } </span>
                    </Col>

                    <Col md={4} className='input-button-box'>
                        <div
                            className='input-button'
                            id={"Popover"+option.order}
                            type="button"
                            style={{background: color, borderColor: index<2 ? appColors.CLUBBIE_WHITE : appColors.LIGHT_BLUE}}
                        > 
                            <span>
                                {index>1 ? <img className='input-icon' alt="input" src={index===2 ? plusIcon: textIcon} /> : <div></div>}
                            </span>
                        </div>

                        <UncontrolledPopover
                            placement="right"
                            trigger="legacy"
                            target={"Popover"+option.order}
                        >
                            <PopoverBody>
                                {
                                    showColor && 
                                    <Row>
                                        <Col>
                                            {
                                                colors.map(item => {
                                                    return (
                                                        <div className={`color-option ${item===color ? "selected" : ""}`} style={{backgroundColor: item}} onClick={()=>updateColor(item)} key={item}></div>
                                                    )
                                                })
                                            }
                                        </Col>
                                    </Row>
                                }
                                {
                                    showInputs && fields && 
                                    <div className='image-form'>
                                        {option.text==="Logos" && <p>For the best results, upload high-quality images with the background removed.</p>}

                                        {inputs.map((field, index) => {
                                            return (
                                                <FormGroup key={field.variable}>
                                                    <div className='image-heading'>
                                                        <Label for={field.variable}>
                                                            {field.label}
                                                        </Label>

                                                        {field.type==='file' && fields['logos'][index]?.value && 
                                                            <img className='remove-icon' alt="remove" src={remove} onClick={()=>handleRemoveImage('logos', index)}/>
                                                        }
                                                    </div>
                                                    
                                                    {
                                                        field.type==='file' ?
                                                            garment && garment!== 'socks' && <DragDropFiles setLogo={(file)=>setLogo(file, 'logos', index)} imageSrc={fields['logos'][index]?.value}/>
                                                        :
                                                            <Input
                                                                id={field.variable}
                                                                name={field.variable}
                                                                type={field.type}
                                                                value={ fields['text'][index]?.value}
                                                                style={{fontSize: '1rem'}}
                                                                onChange={(event)=>setValue(event.target.value, 'text', index)}
                                                                maxLength={type==='training_kits' ? 4 : 10}
                                                            />
                                                    }

                                                </FormGroup>
                                            )
                                        })}
                                    </div>
                                }
                            </PopoverBody>
                        </UncontrolledPopover>
                    </Col>
                </Row>
                }
            </div>

    )
}

export default ColorPicker;