import React from 'react'
import './ClubbieLoader.css'
import clubbieLogo from '../assets/images/clubbie_logo.svg'

function ClubbieLoader() {
  return (
    <div className='spinner'>
        <img src={clubbieLogo} alt='Clubbie'/>
    </div>
  )
}

export default ClubbieLoader