export const fixedTexts = {

    orderMsgs: {
        orderFailedMsg: "Failed to place order. Please try again.",
        orderConfirmedMsg: "Your quote request has been submitted! A member of our team will be in touch with you shortly.",
        noteFromDesignTeam: "*Our design team will ensure all logos are perfectly sized and positioned. You'll also be able to make further changes after receiving your quote!"
    },

    designMsgs: {
        saveFailed: "Failed to save design. Please try again.",
        customDesignA: "Have a design in mind? Share your inspiration with us!",
        customDesignB: "Email your ideas to hello@clubbie.com or DM us on social media, and our talented designers will create a bespoke design just for your team."
    }
}