import male_shirt_1 from '../assets/images/preview_images/male_shirts/male_shirt_1.png'
import male_shirt_2 from '../assets/images/preview_images/male_shirts/male_shirt_2.png'
import male_shirt_3 from '../assets/images/preview_images/male_shirts/male_shirt_3.png'
import male_shirt_4 from '../assets/images/preview_images/male_shirts/male_shirt_4.png'
import male_shirt_5 from '../assets/images/preview_images/male_shirts/male_shirt_5.png'
import male_shirt_6 from '../assets/images/preview_images/male_shirts/male_shirt_6.png'
import male_shirt_7 from '../assets/images/preview_images/male_shirts/male_shirt_7.png'
import male_shirt_8 from '../assets/images/preview_images/male_shirts/male_shirt_8.png'

import shorts_1 from '../assets/images/preview_images/shorts/shorts_1.png'
import shorts_2 from '../assets/images/preview_images/shorts/shorts_2.png'
import shorts_3 from '../assets/images/preview_images/shorts/shorts_3.png'
import shorts_4 from '../assets/images/preview_images/shorts/shorts_4.png'

import socks_1 from '../assets/images/preview_images/socks/socks_1.png'
import socks_2 from '../assets/images/preview_images/socks/socks_2.png'


export const designOptions = {
    player: {
        shirts: {
            color: {
                options: [
                    {order: 1, text: "Primary", icon: "", value: null, default: "#0a0b1d"},
                    {order: 2, text: "Secondary", icon: "", value: null, default: "#fac921"},
                    {order: 3, text: "Logos", inputs: true, icon: "", value: null, default: "transparent"},
                    {order: 4, text: "Text", inputs: true, icon: "", value: null, default: "transparent", defaultColor: "#FEFCEB"},
                ],
            },
            types:{
                options: {
                    matchday_kits: {
                        male: [
                            {order: 1, image: male_shirt_1},
                            {order: 2, image: male_shirt_2},
                            {order: 3, image: male_shirt_3},
                            {order: 4, image: male_shirt_4},
                            {order: 5, image: male_shirt_5},
                            {order: 6, image: male_shirt_6},
                            {order: 7, image: male_shirt_7},
                            {order: 8, image: male_shirt_8},
                        ],
                    },
                },
                selection: null
            }
        },
        shorts: {
            color: {
                options: [
                    {order: 1, text: "Primary", icon: "", value: null, default: "#0a0b1d"},
                    {order: 2, text: "Secondary", icon: "", value: null, default: "#fac921"},
                    {order: 3, text: "Logos", inputs: true, icon: "", value: null, default: "transparent"},
                    {order: 4, text: "Text", inputs: true, icon: "", value: null, default: "transparent", defaultColor: "#FEFCEB"},
                ],
            },
            types:{
                options: {
                    male: [
                        {order: 1, image: shorts_1},
                        {order: 2, image: shorts_2},
                        {order: 3, image: shorts_3},
                        {order: 4, image: shorts_4}
                    ],
                },
                selection: null
            }
        },
        socks: {
            color: {
                options: [
                    {order: 1, text: "Primary", icon: "", value: null, default: "#0a0b1d"},
                    {order: 2, text: "Secondary", icon: "", value: null, default: "#fac921"},
                    {order: 3, text: "Text", inputs: true, icon: "", value: null, default: "transparent", defaultColor: "#FEFCEB"},
                ],
            },
            types:{
                options: {
                    male: [
                        {order: 1, image: socks_1},
                        {order: 2, image: socks_2},
                    ],
                },
                selection: null
            }
        },
    },
}

export const inputSubOptions =  {
    description: "Add logo and text",
    selection: null,
    key: true,
    shirts: {
        matchday_kits:{ 
            logos: [
                {order: 1, value: '',  variable: 'clubCrest', label: 'Club crest', type: 'file', accept: 'image/*'},
                {order: 2, value: '',  variable: 'sponsorLogoFront', label: 'Sponsor logo front', type: 'file', accept: 'image/*'},
                {order: 3, value: '',  variable: 'sponsorLogoLeft', label: 'Sponsor logo left sleeve', type: 'file', accept: 'image/*'},
                {order: 4, value: '',  variable: 'sponsorLogoRight', label: 'Sponsor logo right sleeve', type: 'file', accept: 'image/*'},
                {order: 5, value: '',  variable: 'sponsorLogoNeck', label: 'Neck sponsor logo', type: 'file', accept: 'image/*'},
            ],
            text: [
                {order: 1, value: '',  variable: 'kitNumber', label: 'Kit number', type: 'number'},
                {order: 2, value: '',  variable: 'playerName', label: 'Player name', type: 'text'},
            ]
        },
    },
    shorts: {
        logos: [
            {order: 1, value: '',  variable: 'clubCrest', label: 'Club crest', type: 'file', accept: 'image/*'},
            {order: 2, value: '',  variable: 'sponsorLogoLeft', label: 'Sponsor logo left back', type: 'file', accept: 'image/*'},
            {order: 3, value: '',  variable: 'sponsorLogoRight', label: 'Sponsor logo right back', type: 'file', accept: 'image/*'},
        ],
        text: [
            {order: 1, value: '10',  variable: 'kitNumber', label: 'Kit number', type: 'number'},
        ]
    },
    socks: {
        logos: [],
        text: [
            {order: 1, value: '',  variable: 'sponsorText', label: 'Team Name / Initials', type: 'text'},
        ]
    },
}

export const defaultColors = {
    primary: "#0a0b1d",
    secondary: "#fac921",
    textColor: "#fdf7e2"
}

export const appColors = {
    CLUBBIE_WHITE: "#FEFCEB",
    LIGHT_BLUE: "#5FC4EE",
    LIGHTING_COLOR: "#F9F6EE",
    PREVIEW_IMAGE_BG: "#082441aa",
    PREVIEW_IMAGE_BG_ALT: "#395067aa",
    APP_BACKGROUND: "#000F1F"
}

// export const colorPalette2 =  ["#ffffff", "#c7c9c5", "#8e8f83", "#636357", "#504f47", "#2c2b28", "#80b2d8", "#0d4071", "#07357e", "#1f1f50", "#1a2434", "#ae1d12", "#972c2e", "#a02a25", "#eb5915", "#d47309", "#ed9613", "#f29f02", "#fac921", "#c2b006", "#013d20", "#09744d", "#4a801e", "#75a51a", "#66aa71", "#09766a", "#301836", "#513c66", "#bb0a22", "#c9233a", "#ba7275", "#c8c191", "#8e8d48", "#0a0b1d", "#F1C400", "#000000"]
export const colorPalette = ["#fdf7e2", "#fff6dc", "#fbe2bd", 
                              "#69abd1", "#69a0c2", "#1aa1c8", "#335d94", "#234287", "#24316f", "#2a265f", "#232544", "#0a0b1d",
                              "#78bc5a", "#229358", "#1a774d", "#113028", "#1a311e",
                              "#ffee03", "#fac921", "#e88f2d", "#e15d29",
                              "#c32536", "#ac2736", "#972426", "#762933", "#6b1933", "#4c1820", "#341515",
                              "#e3719c", "#da286a", "#572968", "#251942",
                              "#b5b5bd", "#949093", "#453e41", "#000000"]