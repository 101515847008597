export const modelsInfo = {
    shirts:{
        player:{
            male:{
                model: '/models/male_shirt_stock.glb',
                modelName: 'shirt_male',
                node: 'Dark_Blue_Shiny',
                material: 'Cotton_Sateen_FRONT_2029',
                rotation:[1.57, 0, 0]
            },
        },
    },
    shorts:{
        model: '/models/shorts_stock.glb',
        modelName: 'shorts',
        node:'Cloth005',
        material: 'Cotton_Canvas_7546.002',
        rotation:[0, 0, 0]
    },
    socks:{
        model: '/models/socks_stock.glb',
        modelName: 'socks',
        node:'Cloth003',
        material: 'Cotton_Canvas_119863.001',
        rotation:[0, 0, 0]
    }
}