import React from 'react';
import './PopUpOption.css';
import { Button } from 'reactstrap';

function PopUpOption({text, minWidth='300px', click, disabled, isDelete}) {

    return (
        <Button className={`pop-button ${isDelete ? "delete" : ''}`} onClick={() => click()} style={{minWidth}} disabled={disabled}>
            {text}
        </Button>
    );
}

export default PopUpOption;
