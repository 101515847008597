import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    sessionDesign: {"shirts": null, "shorts": null, "socks": null},
};

const sessionDesignSlice = createSlice({ 
    name: 'sessionDesign',
    initialState,
    reducers: {
        saveSessionDesign: (state, action) => {
            const data = action.payload

            state.sessionDesign[data?.garment] = {
                designSelection: data.designSelection, 
                selectedInputOptions: data.selectedInputOptions
            }
        }, 

        clearSessionDesigns: (state) => {
            state.sessionDesign = {"shirts": null, "shorts": null, "socks": null}
        }
     }
})

export const { 
    saveSessionDesign,
    clearSessionDesigns
} = sessionDesignSlice.actions

export const getSessionDesigns = (state) => state.sessionDesign.sessionDesign;

export default sessionDesignSlice.reducer;
