import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    designs: null,
};

const designsSlice = createSlice({ 
    name: 'designs',
    initialState,
    reducers: {
        loadDesigns: (state, action) => {
            const data = action.payload
            state.designs = data 
        }, 
     }
})

export const { 
    loadDesigns,
} = designsSlice.actions

export const getLoadedDesigns = (state) => state.designs.designs;

export default designsSlice.reducer;