import { defaultColors } from '../utils/studioSettings';
const {createCanvas, loadImage} = require('canvas');
const utils = require("./textureUtils");


class TextureCreator
{
    constructor() 
    {
        this.imageSize = 4096
        this.bufferSize = 2048
        this.canvas = createCanvas(this.imageSize, this.imageSize); 
    }

    createDesign(primaryColor, secondaryColor, design) 
    {
        const ctx = this.canvas.getContext('2d')
        ctx.fillStyle = primaryColor;
        ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

        for(let i=0; i<design.length; i++)
        {
            let component = design[i]

            let designComponent = component["component"]

            let designCanvas = createCanvas(designComponent.width, designComponent.height); 

            let destX = component["x"];
            let destY = component["y"];
            let type = component["type"]

            let designCtx = designCanvas.getContext("2d")
            designCtx.drawImage(designComponent, 0, 0);
            var imageData = designCtx.getImageData(0, 0, designCanvas.width, designCanvas.height);
            var data = imageData.data;

            if (type === 0 || type === 1)
            {
                let rgbArray = []

                if (type === 0)
                {
                    rgbArray = utils.hexToRgb(primaryColor)
                }

                else
                {
                    rgbArray = utils.hexToRgb(secondaryColor)
                }
                
                for (let j = 0; j < data.length; j += 4) {
                    if (data[j+3] !== 0)
                    {
                        data[j] = rgbArray[0];
                        data[j + 1] = rgbArray[1];
                        data[j + 2] = rgbArray[2];
                    }
                }
            }
                
            designCtx.putImageData(imageData, 0, 0);
            ctx.drawImage(designCanvas, destX, destY);
        }
        
        return ctx
    }

    shirtStandard(ctx, clubbieLogo, clubCrest, kitNumber, playerName, sponsorLogoFront, sponsorLogoRight, sponsorLogoLeft, sponsorLogoNeck, textFont, textColor)
    {
        ctx.drawImage(clubCrest, this.clubCrestX, this.clubCrestY, this.clubCrestWidth, this.clubCrestHeight);

        let scaledXywh = utils.getScaledXyhw(this.sponsorLogoRightX, this.sponsorLogoRightY, 
                                                sponsorLogoRight.height, sponsorLogoRight.width, this.sponsorLogoMaxDim)
        ctx.drawImage(sponsorLogoRight, scaledXywh[0], scaledXywh[1], scaledXywh[2], scaledXywh[3]);

        scaledXywh = utils.getScaledXyhw(this.sponsorLogoLeftX, this.sponsorLogoLeftY, 
                                            sponsorLogoLeft.height, sponsorLogoLeft.width, this.sponsorLogoMaxDim)
        ctx.drawImage(sponsorLogoLeft, scaledXywh[0], scaledXywh[1], scaledXywh[2], scaledXywh[3]);
        
        scaledXywh = utils.getScaledXyhw(this.sponsorLogoNeckX, this.sponsorLogoNeckY, 
                                            sponsorLogoNeck.height, sponsorLogoNeck.width, this.sponsorLogoNeckMaxDim)
        ctx.drawImage(sponsorLogoNeck, scaledXywh[0], scaledXywh[1], scaledXywh[2], scaledXywh[3]);

        scaledXywh = utils.resizeToFitFront(this.sponsorLogoFrontX, 
                                            sponsorLogoFront.height, sponsorLogoFront.width, this.sponsorLogoNeckMaxHeight, this.sponsorLogoNeckMaxWidth)
        ctx.drawImage(sponsorLogoFront, scaledXywh[0], this.sponsorLogoFrontY, scaledXywh[1], scaledXywh[2]);


        ctx.fillStyle = textColor;
        ctx.textAlign = this.nameNumberAlign;
    
        ctx.font = `${this.playerNameFontSize} ${textFont}`
        ctx.fillText(playerName, this.playerNameX, this.playerNameY)
    
        ctx.font = `${this.kitNumberFontSize} ${textFont}` 
        ctx.fillText(kitNumber, this.kitNumberX, this.kitNumberY)

        ctx.drawImage(clubbieLogo, this.clubbieLogoFrontX, this.clubbieLogoFrontY, this.clubbieLogoWidth, this.clubbieLogoHeight);

        return ctx
    }

    shortsStandard(ctx, clubbieLogo, clubCrest, kitNumber, sponsorLogoRight, sponsorLogoLeft, textFont, textColor)
    {
        ctx.drawImage(clubCrest, this.clubCrestX, this.clubCrestY, this.clubCrestWidth, this.clubCrestHeight);

        let scaledXywh = utils.getScaledXyhw(this.sponsorLogoRightX, this.sponsorLogoRightY, 
                                                sponsorLogoRight.height, sponsorLogoRight.width, this.sponsorLogoMaxDim)
        ctx.drawImage(sponsorLogoRight, scaledXywh[0], scaledXywh[1], scaledXywh[2], scaledXywh[3]);

        scaledXywh = utils.getScaledXyhw(this.sponsorLogoLeftX, this.sponsorLogoLeftY, 
                                            sponsorLogoLeft.height, sponsorLogoLeft.width, this.sponsorLogoMaxDim)
        ctx.drawImage(sponsorLogoLeft, scaledXywh[0], scaledXywh[1], scaledXywh[2], scaledXywh[3]);
        
        ctx.fillStyle = textColor;
        ctx.textAlign = this.numberAlign;
        ctx.font = `${this.kitNumberFontSize} ${textFont}` 
        ctx.fillText(kitNumber, this.kitNumberX, this.kitNumberY)

        ctx.drawImage(clubbieLogo, this.clubbieLogoFrontX, this.clubbieLogoFrontY, this.clubbieLogoWidth, this.clubbieLogoHeight);

        return ctx
    }

    createBuffer()
    {
        const resizedCanvas = createCanvas(this.bufferSize, this.bufferSize);
        const resizedCtx = resizedCanvas.getContext('2d');
        resizedCtx.drawImage(this.canvas, 0, 0, this.imageSize, this.imageSize, 0, 0, this.bufferSize, this.bufferSize);
        const base64Image = resizedCanvas.toDataURL('image/jpeg');

        return base64Image
    }
}

class ShirtTextureCreatorMale extends TextureCreator
{
    constructor() 
    {
        super();

        this.clubCrestWidth = 200
        this.clubCrestHeight = 200
        this.clubCrestX = 1354
        this.clubCrestY = 2023

        this.sponsorLogoMaxDim = 170
        this.sponsorLogoLeftX = 921
        this.sponsorLogoLeftY = 1123
        this.sponsorLogoRightX = 3016 
        this.sponsorLogoRightY = 1007

        this.sponsorLogoNeckMaxDim = 160
        this.sponsorLogoNeckX = 2966
        this.sponsorLogoNeckY = 1569

        this.sponsorLogoNeckMaxHeight = 288
        this.sponsorLogoNeckMaxWidth = 790
        this.sponsorLogoFrontX = 715
        this.sponsorLogoFrontY = 2328

        this.playerNameFontSize = '200px'
        this.kitNumberFontSize = '800px'
        this.nameNumberAlign = "center"
        this.playerNameX = 2491+(1158/2)
        this.playerNameY = 2026
        this.kitNumberX = 2491+(1158/2)
        this.kitNumberY = 2720

        this.clubbieLogoFrontX = 654
        this.clubbieLogoFrontY = 2088
        this.clubbieLogoWidth = 249
        this.clubbieLogoHeight = 57

    }

    async createTexture(clubbieLogo, clubCrest, kitNumber, playerName, sponsorLogoFront, sponsorLogoRight, sponsorLogoLeft, sponsorLogoNeck, primaryColor, secondaryColor, textFont, textColor, design)
    {
        let ctx = this.createDesign(primaryColor, secondaryColor, design)

        this.shirtStandard(ctx, clubbieLogo, clubCrest, kitNumber, playerName, sponsorLogoFront, sponsorLogoRight, sponsorLogoLeft, sponsorLogoNeck, textFont, textColor)

        let textureBuffer = this.createBuffer()

        return textureBuffer
    }
}

class ShortsTextureCreator extends TextureCreator
{
    constructor() 
    {
        super();

        this.clubCrestWidth = 144
        this.clubCrestHeight = 144
        this.clubCrestX = 1082
        this.clubCrestY = 2411

        this.sponsorLogoMaxDim = 230
        this.sponsorLogoLeftX = 2664
        this.sponsorLogoLeftY = 2388
        this.sponsorLogoRightX = 3432 
        this.sponsorLogoRightY = 2385

        this.kitNumberFontSize = '200px'
        this.numberAlign = "center"
        this.kitNumberX = 1997 + 46
        this.kitNumberY = 2477

        this.clubbieLogoFrontX = 1973
        this.clubbieLogoFrontY = 2518
        this.clubbieLogoWidth = 146
        this.clubbieLogoHeight = 34
    }

    async createTexture(clubbieLogo, clubCrest, kitNumber, sponsorLogoRight, sponsorLogoLeft, primaryColor, secondaryColor, textFont, textColor, design)
    {
        let ctx = this.createDesign(primaryColor, secondaryColor, design)

        this.shortsStandard(ctx, clubbieLogo, clubCrest, kitNumber, sponsorLogoRight, sponsorLogoLeft, textFont, textColor)

        let textureBuffer = this.createBuffer()

        return textureBuffer
    }
}

class SocksTextureCreator extends TextureCreator
{

    async createTexture(designNo, primaryColor, secondaryColor, text, textFont, textColor, design)
    {
        let ctx = this.createDesign(primaryColor, secondaryColor, design)

        if (designNo === 1)
        {
            let textCenterXRight = 476
            let textCenterXLeft = 2899
            let textCenterY = 962
            
            let textLength = 64*text.length
            let offsetY = textLength/2
            let textY = textCenterY - offsetY
            ctx.fillStyle = textColor;
            ctx.textAlign = "center";
            let textFontSize = '64px'
            ctx.font = `${textFontSize} ${textFont}`

            let vtext = ""
            for(let i =0; i<text.length; i++)
            {
                vtext = text[i]
                ctx.fillText(vtext, textCenterXRight,textY);
                ctx.fillText(vtext, textCenterXLeft,textY);
                textY = textY + 64 + 12
            }
        }

        else if (designNo === 2)
        {
            let textXLeft = 515
            let textXRight = 2848
            let textY = 1037
            ctx.fillStyle = textColor;
            ctx.textAlign = "center";
            let textFontSize = '128px'
            ctx.font = `${textFontSize} ${textFont}`
            ctx.fillText(text, textXRight,textY);
            ctx.fillText(text, textXLeft,textY)
        }

        let textureBuffer = this.createBuffer()

        return textureBuffer
    }

}


async function createTexture(design_, selections, logos, texts)
{   
    const design = JSON.parse(JSON.stringify(design_));

    for(let j=0; j<design.length; j++)
    {
        let component = design[j]
        if(typeof component.component === 'string'){
            let base64string = component["component"]
            design[j]["component"] = await loadImage(base64string)
        }
    }

    let keyTexts = {kitNumber: "", playerName: "", sponsorText: ""}
    let keyLogos = {sponsorLogoFront: "", clubCrest: "", sponsorLogoRight: "", sponsorLogoLeft: "", sponsorLogoNeck: ""}
    Object.keys(keyTexts).forEach(key => {
        let temp = texts.find(item => item.variable === key)
        keyTexts[key] = temp ? temp.value : ""
    })

    let blank = await loadImage("./assets/blank.png")
    Object.keys(keyLogos).forEach(async key => {
        let temp = logos.find(item => item.variable === key)
        keyLogos[key] = temp && temp.value.length ? await loadImage(temp.value) : blank
    })

    let kitNumber = keyTexts["kitNumber"]
    let playerName = keyTexts["playerName"]
    let text = keyTexts["sponsorText"]

    let textFont = "ProximaNovaBold, sans-serif"
    let textColor = selections.textColor
    let clubbieLogo = await loadImage("./assets/Clubbie_Full_logo.png")


    let clubCrest = keyLogos["clubCrest"]
    let sponsorLogoRight = keyLogos["sponsorLogoRight"]
    let sponsorLogoLeft = keyLogos["sponsorLogoLeft"]
    let sponsorLogoNeck = keyLogos["sponsorLogoNeck"]
    let sponsorLogoFront = keyLogos["sponsorLogoFront"]

    
    let primaryColor = selections.primary
    let secondaryColor = selections.secondary
    
    let texture = null;
    let designBuffer = null

    try{
        if(selections.garment === "shirts"){
            if(!selections.keeper){
                if(selections.gender === "male"){
                    if(selections.type === "matchday_kits"){
                        texture = new ShirtTextureCreatorMale();
                        designBuffer = await texture.createTexture(clubbieLogo, clubCrest, kitNumber, playerName, sponsorLogoFront, sponsorLogoRight, sponsorLogoLeft, sponsorLogoNeck, primaryColor, secondaryColor, textFont, textColor, design)
                    }
                }
            }
        }else if(selections.garment === "shorts"){
            texture = new ShortsTextureCreator()
            designBuffer = await texture.createTexture(clubbieLogo, clubCrest, kitNumber, sponsorLogoRight, sponsorLogoLeft, primaryColor, secondaryColor, textFont, textColor, design)
        }else{
            texture = new SocksTextureCreator()
            designBuffer = await texture.createTexture(selections.option, primaryColor, secondaryColor, text, textFont, textColor, design)
        }   
    }catch(e){
        designBuffer = null
    }
    
    return designBuffer;
}

export async function getTexture(gender, type, garment, keeper, inputs, selectedDesign, design) {
    
    const selections = {
        gender: gender, 
        type: type, 
        garment: garment,
        keeper: keeper,
        option: selectedDesign,
        primary: inputs?.primary ? inputs?.primary : defaultColors.primary,
        secondary: inputs?.secondary ? inputs?.secondary : defaultColors.secondary,
        textColor: inputs?.textColor ? inputs?.textColor : defaultColors.textColor
    }

    const base64Image = await createTexture(design, selections, inputs.logos, inputs.text)

    return base64Image
}

