import React from 'react'
import sizeChart from '../assets/images/size_chart.png'
import './SizeChartPopUp.css'
import {Modal} from 'reactstrap'

function SizeChartPopUp({showChart, closePopUp}) {

    return (
        <Modal isOpen={showChart} toggle={closePopUp} backdrop={true} fade={true} centered={true} >
            <img className="size-chart" src={sizeChart} alt="hello"/>
        </Modal>
    );
}

export default SizeChartPopUp;