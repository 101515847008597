import React from 'react'
import PopUpOption from './PopUpOption'
import './PopUpWithMsg.css'

function PopUpWithMsg({onOptionPress, optionText, popUpMsg, closePopUp, isDelete, justMsg, failMsg}) {

    return (
        <div className='general-pop-up'>
            <div className='general-pop-up-banner' >
                <p className={`pop-up-msg ${failMsg ? "fail-msg" : ""}`}>
                    {popUpMsg}
                </p>
                {!justMsg && <PopUpOption text={optionText} click={onOptionPress} isDelete={isDelete}/>}
                <PopUpOption text={'Close'} click={closePopUp}/>
            </div>
        </div>
    );
}

export default PopUpWithMsg;
