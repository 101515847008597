import React, { useState,  useEffect, useCallback } from 'react'
import './Studio.css'
import { Col, Row } from 'react-bootstrap';
import ClubbieLoader from '../components/ClubbieLoader';
import { getDesignData, getStudioDesignOptions } from '../lib/actions';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ColorPicker from '../components/ColorPicker';
import { Stage } from '../components/Stage';
import { AUTHENTICATION_STACK, STUDIO_STACK } from '../utils/screenNames';
import SaveDesignPopUp from '../components/SaveDesignPopUp';
import OverlayLoader from '../components/OverlayLoader';
import { addToCart } from '../redux/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { saveDesign } from '../lib/actions';
import TopButtons from '../components/TopButtons';
import { Tabs, Tab } from '@mui/material';
import backButton from '../assets/images/back_button.svg'
import BottomButtonsMobile from '../components/BottomButtonsMobile';
import { saveSessionDesign, getSessionDesigns } from '../redux/sessionDesignSlice';
import PopUpWithMsg from '../components/PopUpWithMsg';
import { refreshTokens } from '../lib/authActions';
import { isTokenExpired } from '../utils/helpers';
import { defaultSelections } from '../utils/optionSets';
import { fixedTexts } from '../utils/fixedTexts';
import { appColors } from '../utils/studioSettings';
import { loadDesigns, getLoadedDesigns } from '../redux/designsSlice';

function Studio() {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const sessionDesigns = useSelector(getSessionDesigns)
  const loadedDesigns = useSelector(getLoadedDesigns) 

  const location = useLocation()

  const stateFromNavigation = location?.state

  const [designComponents, setDesignComponents] = useState()
  const [designOptions, setDesignOptions] = useState()
  const [inputOptions, setInputOptions] = useState()
  const [selectedInputOptions, setSelectedInputOptions] = useState()
  const [designSelection, setDesignSelection] = useState(1)
  const [selections, setSelections] = useState()
  const [currentGarment, setCurrentGarment] = useState("shirts")

  const garmentIndices = {'shirts': 0, 'shorts': 1, 'socks': 2}

  const [showPopUp, setShowPopUp] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [changingGarment, setChangingGarment] = useState(false)
  const [disableGarmentSwitch, setDisableGarmentSwitch] = useState(false)
  const [showMakeAccountPopUp, setShowMakeAccountPopUp] = useState(false)
  const [showSaveFailedPopUp, setShowSaveFailedPopUp] = useState(false)

  const [savedDesignIndex, setSavedDesignIndex] = useState(null)

  const screenWidth = window.innerWidth;
  const isComputer = screenWidth > 800 
  
  let designsRowSpan = 4; 
  if (screenWidth<=2560 && screenWidth>1440){
    designsRowSpan = 4
  }
  else if (screenWidth<=1024 && screenWidth>768){
    designsRowSpan = 6
  }

  const setDesigns = useCallback( async (garment) => {
    function onSuccess (designData) {
        dispatch(loadDesigns(designData))
        setDesignComponents(designData[garment])
        setIsLoading(false)
    }   

    function onFail () {
        setIsLoading(false)
    }

    if(loadedDesigns){
        setDesignComponents(loadedDesigns[garment])
        setIsLoading(false)
    }
    else{
        await getDesignData(onSuccess, onFail)
    }

  }, [dispatch, loadedDesigns])


  const handleSessionDesignSave = useCallback((selectedGarment, sessionSelectedInputs, sessionSelectedDesign) => {
    const designToSave = {designSelection: sessionSelectedDesign, selectedInputOptions: sessionSelectedInputs}

    dispatch(saveSessionDesign({...designToSave, garment: selectedGarment}))

  }, [dispatch])


  const handleDesignSelection  = useCallback((selection) => {
    setDesignSelection(selection)
    if(!disableGarmentSwitch){
        handleSessionDesignSave(currentGarment, selectedInputOptions, selection)
    }

  }, [setDesignSelection, handleSessionDesignSave, disableGarmentSwitch, currentGarment, selectedInputOptions])


  const updateInputs = useCallback((newInputs) => {
    setSelectedInputOptions(newInputs)
    if(!disableGarmentSwitch){
        handleSessionDesignSave(currentGarment, newInputs, designSelection)
    }

  }, [setSelectedInputOptions, handleSessionDesignSave, disableGarmentSwitch, currentGarment, designSelection])


  const goToAuth = () => {
    navigate(AUTHENTICATION_STACK.LOGIN_OPTIONS)
  }

  const handleSave = useCallback(async () => {

        const accessString = window.localStorage.getItem("access")
        const refreshString = window.localStorage.getItem("refresh")

        if(accessString){

            setIsSaving(true)

            const accessTokenObj = JSON.parse(accessString)

            function onSuccess () {
                if(savedDesignIndex!==null){
                    navigate(STUDIO_STACK.SAVED_DESIGNS, {state: {selections: selections, from: location.pathname} })
                }
                else{
                    setIsSaving(false)
                    setShowPopUp(true)
                }
            }   
    
            function onFail () {
                if(savedDesignIndex){
                    navigate(STUDIO_STACK.SAVED_DESIGNS)
                }
                else{
                    setIsSaving(false)
                    setShowSaveFailedPopUp(true)
                }
            }
    
            const saveBody = {
                designNumber: designSelection, 
                specs: selectedInputOptions, 
                selections: selections
            }

            if(isTokenExpired(accessTokenObj.expiry)){
                const refresh = JSON.parse(refreshString)

                const handleResetTokens = async (refreshToken) => {
                    function onResetSuccess (data) {
                        const {access, refresh} = data
                        window.localStorage.setItem("access", JSON.stringify(access))
                        window.localStorage.setItem("refresh", JSON.stringify(refresh))

                        saveDesign(saveBody, savedDesignIndex, access, onSuccess, onFail)
                    }
                
                    function onResetFail () {
                        setIsSaving(false)
                        setShowMakeAccountPopUp(true)
                    }
                
                    await refreshTokens(refreshToken, onResetSuccess, onResetFail)
                }

                handleResetTokens(refresh)
            }

            else {
                await saveDesign(saveBody, savedDesignIndex, accessTokenObj, onSuccess, onFail)
            }
        }

        else{
            setShowMakeAccountPopUp(true)
        }


  }, [designSelection, selectedInputOptions, selections, savedDesignIndex, navigate, location, setIsSaving])


  const handleContinueDesigning = useCallback(() => {
    setShowPopUp(false)
  }, [setShowPopUp])


  const handleAddToCart = useCallback(() => {

    const cartItem = {design: designComponents[designSelection], selections, designSelection, selectedInputOptions}

    dispatch(addToCart(cartItem))

    setShowPopUp(false)

  }, [dispatch, setShowPopUp, designComponents, selections, designSelection, selectedInputOptions])


  const handleSwitchGarment = (garment) => {

    if(currentGarment!==garment && !disableGarmentSwitch) {
        const studioSelections = {...selections, garment: garment}

        setChangingGarment(true)

        setCurrentGarment(garment)
        setDesignComponents(loadedDesigns[garment])

        const {designs, inputs, selectedInputs} = getStudioDesignOptions(studioSelections)

        setSelections(studioSelections)
        setDesignOptions(designs)
        
        setInputOptions(inputs)

        if(sessionDesigns[garment]){
            setSelectedInputOptions(sessionDesigns[garment].selectedInputOptions)
            setDesignSelection(sessionDesigns[garment].designSelection)
        }
        else{
            setSelectedInputOptions(selectedInputs)
            setDesignSelection(1)
        }

        setChangingGarment(false)
    }
    }

  const handleBack = () => {
        const accessString = window.localStorage.getItem("access")
        if(accessString) {
            navigate(STUDIO_STACK.SELECTION_PAGE)
        }
        else{
            navigate("/")
        }
    }

  useEffect(() => {
    const studioSelections = stateFromNavigation && stateFromNavigation?.selections ? stateFromNavigation?.selections : defaultSelections

    setDesigns(studioSelections?.garment)

    setSelections(studioSelections)
    setCurrentGarment(studioSelections?.garment)

    const {designs, inputs, selectedInputs} = getStudioDesignOptions(studioSelections)

    setDesignOptions(designs)
    setInputOptions(inputs)
    
    if(stateFromNavigation && stateFromNavigation?.index!==null && stateFromNavigation?.index!==undefined){
        setSavedDesignIndex(stateFromNavigation?.index)
        setDesignSelection(stateFromNavigation?.designNumber)
        setSelectedInputOptions(stateFromNavigation?.specs)
        setDisableGarmentSwitch(true)
    }
    else if(sessionDesigns[studioSelections?.garment]){
        setSelectedInputOptions(sessionDesigns[studioSelections?.garment].selectedInputOptions)
        setDesignSelection(sessionDesigns[studioSelections?.garment].designSelection)
    }
    else{
        setSelectedInputOptions(selectedInputs)
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
    {isComputer ?
    (
        <div className='studio-page'>
            <div className={`studio-top-bar`}>
                    <div className={`studio-page-heading ${isLoading ? "" : 'slide'}`}>
                        Design Studio</div>         
            </div>

            {isLoading ? <ClubbieLoader /> : 
                <div>
                    {
                    <Row className='mx-2 mt-4'>
                        <Col md={3} className='px-3'>
                            {
                                inputOptions.map((option, index) => {
                                    if(!changingGarment){
                                        return <ColorPicker 
                                                    key={'color_'+option.order+`_${currentGarment}`} 
                                                    option={option} 
                                                    index={index} 
                                                    showColor={option.text === "Logos" ? false : true}
                                                    showInputs={option.inputs && selectedInputOptions[option.text.toLowerCase()]}
                                                    inputs={selectedInputOptions[option.text.toLowerCase()]}
                                                    inputTitle={option.text.toLowerCase() } 
                                                    garment={currentGarment}
                                                    type={selections?.item?.toLowerCase()?.replace(/ /g, '_')}
                                                    prevInputs={selectedInputOptions}
                                                    updateInputs={updateInputs}
                                                />    
                                    }
                                    return <></>
                                })
                            }
                        </Col>
                    
                            
                        <Col md={5}>
                        <div className="scrollable-container">
                            <Row className='mx-1'>
                                {
                                    designOptions.map((des, index) => {
                                        return <Col onClick={()=>handleDesignSelection(des.order)} md={designsRowSpan} className={'p-3'+ (designSelection === des.order ? ' selected':' not-selected')} style={{backgroundColor: index%2===0 ? appColors.PREVIEW_IMAGE_BG: appColors.PREVIEW_IMAGE_BG_ALT, cursor: 'pointer'}} key={'shirts_'+des.order}>
                                                    <img src={des.image} alt={`design-${des.order}`} className='design-image'/>
                                                </Col>
                                    })
                                }   
                            </Row> 
                        </div>
                        </Col>

                        <Col md={4} className='studio-stage'>
                            {!changingGarment && 
                                    <Stage design={designComponents[designSelection]} 
                                    selections={selections} 
                                    selectedDesign={designSelection}
                                    selectedInputOptions={selectedInputOptions}
                                    studio={true}
                                    /> 
                            }
                        </Col>  
                    
                    </Row>}

                    <div className='bottom-bar'>
                        <div>
                            <button className="bottom-button" onClick={handleBack}>
                                BACK
                            </button>

                            <button className="bottom-button" onClick={handleSave}>
                                SAVE DESIGN
                            </button>
                        </div>

                                
                        {!disableGarmentSwitch && 
                        <Tabs value={garmentIndices[currentGarment]}  className='garment-tabs'>
                            <Tab label="shirts" className="garment-tab" onClick={()=> handleSwitchGarment("shirts")} 
                                sx={{ '&.Mui-selected': selectedGarmentColor}}/>
                            <Tab label="shorts" className="garment-tab" onClick={()=> handleSwitchGarment("shorts")}
                                sx={{ '&.Mui-selected': selectedGarmentColor}}/>
                            <Tab label="socks" className="garment-tab" onClick={()=> handleSwitchGarment("socks")}
                                sx={{ '&.Mui-selected': selectedGarmentColor}}/>
                        </Tabs>}

                    </div>
                    
                    <TopButtons selections={selections} showSavedDesigns={true}/>
                </div>
            }

            {showPopUp && <SaveDesignPopUp design={designComponents[designSelection]} 
                                            selections={selections} 
                                            selectedDesign={designSelection}
                                            selectedInputOptions={selectedInputOptions}
                                            closePopUp={handleContinueDesigning}
                                            addToCart={handleAddToCart}/> }

            {showMakeAccountPopUp && <PopUpWithMsg 
                                    onOptionPress={goToAuth} 
                                    optionText={'Login'} 
                                    popUpMsg={'Login to your account to continue'} 
                                    closePopUp={()=>setShowMakeAccountPopUp(false)} />}

            {showSaveFailedPopUp && <PopUpWithMsg 
                                        popUpMsg={fixedTexts.designMsgs.saveFailed}
                                        justMsg={true} 
                                        failMsg={true}
                                        closePopUp={
                                            ()=>{setShowSaveFailedPopUp(false)
                                            }}/>}

            {isSaving && <OverlayLoader />}
        </div>
    ) : 
    
    (
        <div className='studio-page'> 
            <div className='mt-3 px-3 back-button-bar'>
                <img className='back-button-mobile' src={backButton} alt="back" onClick={handleBack}/> 
                <span className='back-button-text' onClick={handleBack}>back</span>
            </div>

            <div className={`studio-top-bar`}>
                    <div className={'studio-page-heading-mobile'}>
                        Design Studio
                    </div>   
            </div>
            {isLoading ? <ClubbieLoader /> : 
                <div>
                    <Row>
                        <Col md={12} className='studio-stage'>
                            {changingGarment ? <ClubbieLoader /> :
                             <Stage design={designComponents[designSelection]} 
                                    selections={selections} 
                                    selectedDesign={designSelection}
                                    selectedInputOptions={selectedInputOptions}
                                    studio={true}
                            />}
                        </Col> 
                        <Col md={12} className='garment-tabs'>
                            {!disableGarmentSwitch && 
                            <Tabs value={garmentIndices[currentGarment]}  className='garment-tabs'>
                                <Tab label="shirts" className="garment-tab" onClick={()=> handleSwitchGarment("shirts")} 
                                    sx={{ '&.Mui-selected': selectedGarmentColor}}/>
                                <Tab label="shorts" className="garment-tab" onClick={()=> handleSwitchGarment("shorts")}
                                    sx={{ '&.Mui-selected': selectedGarmentColor}}/>
                                <Tab label="socks" className="garment-tab" onClick={()=> handleSwitchGarment("socks")}
                                    sx={{ '&.Mui-selected': selectedGarmentColor}}/>
                            </Tabs>}
                        </Col>

                        <Col md={12} className="mt-4 scrollable-container-mobile">
                            <Col md={12} className='image-row'>
                                {
                                    designOptions.map((des, index) => {
                                        return <img src={des.image} alt={`design-${des.order}`} className={'row-design-image' + (designSelection === des.order ? ' selected':' not-selected')} onClick={()=>handleDesignSelection(des.order)}/>
                                    })
                                }   
                            </Col>
                        </Col>

                        <Col md={12} className='mt-3'>
                            <Col>
                            {
                                inputOptions.map((option, index) => {
                                    if(!changingGarment){
                                        if(!(currentGarment==='socks' && option.text==="Logos")){
                                            return <ColorPicker 
                                                        key={'color_'+option.order} 
                                                        option={option} 
                                                        index={index} 
                                                        showColor={option.text==="Logos"?false:true}
                                                        showInputs={option.inputs && selectedInputOptions[option.text.toLowerCase()]}
                                                        inputs={selectedInputOptions[option.text.toLowerCase()]}
                                                        inputTitle={option.text.toLowerCase() } 
                                                        garment={currentGarment}
                                                        type={selections?.item?.toLowerCase()?.replace(/ /g, '_')}
                                                        prevInputs={selectedInputOptions}
                                                        updateInputs={updateInputs}
                                                    />    
                                        }    
                                    }
                                    return <></>
                                })
                            }
                            </Col>
                        </Col>
                        
                        <div className='save-button-mobile'>
                            <button className="mt-3 mb-3 bottom-button" onClick={handleSave}>
                                Save Design
                            </button>
                        </div>

                        <BottomButtonsMobile selections={selections} />

                        {showPopUp && <SaveDesignPopUp design={designComponents[designSelection]} 
                                            selections={selections} 
                                            selectedDesign={designSelection}
                                            selectedInputOptions={selectedInputOptions}
                                            closePopUp={handleContinueDesigning}
                                            addToCart={handleAddToCart}/> }

                        {showMakeAccountPopUp && <PopUpWithMsg 
                                    onOptionPress={goToAuth} 
                                    optionText={'Login'} 
                                    popUpMsg={'Login to your account to continue'} 
                                    closePopUp={()=>setShowMakeAccountPopUp(false)} />}

                        {showSaveFailedPopUp && <PopUpWithMsg 
                                                popUpMsg={fixedTexts.designMsgs.saveFailed}
                                                justMsg={true} 
                                                failMsg={true}
                                                closePopUp={
                                                    ()=>{setShowSaveFailedPopUp(false)
                                                    }}/>}

                        {isSaving && <OverlayLoader />}


                    </Row>
                </div>
            }
        </div>
    ) }
</>

  )
}


const selectedGarmentColor = {
    color: appColors.LIGHT_BLUE,
}

export default Studio