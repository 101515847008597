import React from 'react'
import './TermsAndConditions.css'
import backButton from '../../assets/images/back_button.svg'
import { useNavigate } from 'react-router-dom';

function TermsAndConditions() {

   const navigate = useNavigate()

   const goBack = () => {
    navigate(-1)
   }

  return (
    <div className='terms-and-conditions'>
        <div className='mt-3 px-3 mb-3 back-button-bar'>
                <img className='back-button-mobile' src={backButton} alt="back" onClick={goBack}/> 
                <span className='back-button-text' onClick={goBack}>Back</span>
        </div>

      <p >
      These terms and conditions ("Terms") govern your use of Clubbie, a mobile application ("App"), provided by Clubbie Ltd. ("Clubbie," "we," "us," or "our"). By downloading, accessing, or using the App, you agree to these Terms. If you do not agree with these Terms, you may not use the App. 
      </p>
      <p className='section-title' >
        1. Acceptance of Terms 
      </p>
      <p >
        1.1 By downloading, accessing, or using the App, you agree to be bound by these Terms, our Privacy Policy, and any other policies or guidelines referenced herein. If you do not agree to these Terms, please do not use the App. 
      </p>
                    <br/>
      
      <p className='section-title'>
        2. Description of the App 
      </p>
      <p >
        2.1 Clubbie is a social platform designed to empower sports communities. It offers features such as a video feed tab, team statistics, player statistics, team management tools, event creation, player availability tracking, matchday results recording, live scoring, and payment processing for sports teams.       
      </p>
      <p >
        2.2 The App consists of three main sections:  
      </p>
      <p >
        Home: Includes a video feed, team statistics, and player statistics. 
      </p>
      <p >
        Team Hub: A hub for team administration, event management, lineup creation, result recording, live scoring, statistic tracking, and payment processing.  
      </p>
      <p >
        Profile: Contains user profile information, team memberships, payment details, and personal statistics. 
      </p>
                    <br/>

      <p className='section-title'>
        3. User Accounts 
      </p>
      <p >
        3.1 In order to access certain features of Clubbie, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. 
      </p>
      <p >
        3.2 You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.       
      </p>
                    <br/>

      <p className='section-title'>
        4. User Content 
      </p>
      <p >
        4.1 By using the App, you may create, post, or share content ("User Content"). You retain ownership of any intellectual property rights that you hold in the User Content. 
      </p>
      <p >
        4.2 By posting User Content on the App, you grant Clubbie a non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use, reproduce, modify, adapt, publish, translate, distribute, perform, and display such User Content in connection with the operation of the App.       
      </p>
                    <br/>
      
      <p className='section-title'>
        5. Prohibited Conduct 
      </p>
      <p >
        5.1 You agree not to engage in any of the following prohibited activities: 
      </p>
      
        <li >
           Violating any applicable law or regulation. 
        </li>
      
        <li >
          Posting or transmitting any content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or otherwise objectionable.
        </li>
      
        <li>
          Impersonating any person or entity, or falsely stating or otherwise misrepresenting your affiliation with a person or entity. 
        </li>
      
        <li >
          Interfering with or disrupting the operation of the App or any servers or networks connected to the App. 
        </li>
      
        <li>
          Collecting or storing personal data about other users without their express consent. 
        </li>
                    <br/>
      
      <p className='section-title'>
        6. Modifications to the App       
      </p>
      <p >
        6.1 Clubbie reserves the right to modify or discontinue, temporarily or permanently, the App (or any part thereof) with or without notice. You agree that Clubbie shall not be liable to you or to any third party for any modification, suspension, or discontinuance of the App.      
      </p>
                    <br/>

      <p className='section-title'>
        7. Termination       
      </p>
      <p >
        7.1 We may terminate or suspend your access to the App immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms.       
      </p>
      <p >
        7.2 Upon termination, your right to use the App will immediately cease. All provisions of these Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.       
      </p>
                    <br/>

      <p className='section-title'>
        8. Disclaimer of Warranties       
      </p>
      <p >
        8.1 THE APP IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. CLUBBIE MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE APP OR THE CONTENT INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THE APP IS AT YOUR SOLE RISK.       
      </p>
                    <br/>

      <p className='section-title'>
        9. Limitation of Liability       
      </p>
      <p >
        9.1 IN NO EVENT SHALL CLUBBIE, ITS DIRECTORS, OFFICERS, EMPLOYEES, OR AGENTS BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING WITHOUT LIMITATION, LOSS OF PROFITS, DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, ARISING OUT OF OR IN CONNECTION WITH YOUR ACCESS TO OR USE OF THE APP.       
      </p>
                    <br/>

      <p className='section-title'>
        10. Governing Law    
      </p>
      <p >
        10.1 These Terms shall be governed by and construed in accordance with the laws of England, without regard to its conflict of law provisions. 
      </p>
                    <br/>

      <p className='section-title'>
        11. Changes to Terms 
      </p>
      <p >
        11.1 We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. 
      </p>
                    <br/>

      <p className='section-title'>
        12. Contact Us 
      </p>
      <p >
        12.1 If you have any questions about these Terms, please contact us at hello@clubbie.com 
      </p>
                    <br/>

      <p >
        These Terms were last updated on 15 April 2024.
      </p>
    </div>
  )
}

export default TermsAndConditions