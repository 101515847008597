import React from 'react'
import { Routes, Route } from 'react-router-dom';
import Landing from './pages/Authentication/Landing'
import LoginOptions from './pages/Authentication/LoginOptions';
import RegisterOptions from './pages/Authentication/RegisterOptions';
import RegisterWithEmail from './pages/Authentication/RegisterWithEmail';
import LoginWithEmail from './pages/Authentication/LoginWithEmail';
import RegisterWithMobile from './pages/Authentication/RegisterWithMobile';
import LoginWithMobile from './pages/Authentication/LoginWithMobile';
import Verification from './pages/Authentication/Verification';
import ForgotPasswordWithEmail from './pages/Authentication/ForgotPasswordWithEmail';
import ResetPasswordWithEmail from './pages/Authentication/ResetPasswordWithEmail';
import ForgotPasswordWithMobile from './pages/Authentication/ForgotPasswordWithMobile';
import ResetPasswordWithMobile from './pages/Authentication/ResetPasswordWithMobile';

import TermsAndConditions from './pages/Authentication/TermsAndConditions';
import PrivacyPolicy from './pages/Authentication/PrivacyPolicy';

import Selection from './pages/Selection';
import Studio from './pages/Studio';
import Cart from './pages/Cart';
import SavedDesigns from './pages/SavedDesigns';
import { AUTHENTICATION_STACK, STUDIO_STACK } from './utils/screenNames';


function App() {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path={AUTHENTICATION_STACK.LOGIN_OPTIONS} element={<LoginOptions />} />
      <Route path={AUTHENTICATION_STACK.REGISTER_OPTIONS} element={<RegisterOptions />} />
      <Route path={AUTHENTICATION_STACK.REGISTER_WITH_EMAIL} element={<RegisterWithEmail />} />
      <Route path={AUTHENTICATION_STACK.LOGIN_WITH_EMAIL} element={<LoginWithEmail />} />
      <Route path={AUTHENTICATION_STACK.REGISTER_WITH_MOBILE} element={<RegisterWithMobile />} />
      <Route path={AUTHENTICATION_STACK.LOGIN_WITH_MOBILE} element={<LoginWithMobile />} />
      <Route path={AUTHENTICATION_STACK.VERIFICATION} element={<Verification />} />
      <Route path={AUTHENTICATION_STACK.FORGOT_PASSWORD_WITH_EMAIL} element={<ForgotPasswordWithEmail />} />
      <Route path={AUTHENTICATION_STACK.RESET_PASSWORD_WITH_EMAIL} element={<ResetPasswordWithEmail />} />
      <Route path={AUTHENTICATION_STACK.FORGOT_PASSWORD_WITH_MOBILE} element={<ForgotPasswordWithMobile />} />
      <Route path={AUTHENTICATION_STACK.RESET_PASSWORD_WITH_MOBILE} element={<ResetPasswordWithMobile />} />

      <Route path={AUTHENTICATION_STACK.PRIVACY_POLICY} element={<PrivacyPolicy />} />
      <Route path={AUTHENTICATION_STACK.TERMS_AND_CONDITIONS} element={<TermsAndConditions />} />

      <Route path={STUDIO_STACK.SELECTION_PAGE} element={<Selection />}/>
      <Route path={STUDIO_STACK.DESIGN_STUDIO} element={<Studio />}/>
      <Route path={STUDIO_STACK.CART} element={<Cart />}/>
      <Route path={STUDIO_STACK.SAVED_DESIGNS} element={<SavedDesigns />}/>

    </Routes>
  )
}

export default App