import React from 'react'
import PopUpOption from './PopUpOption'
import './OrderPopUp.css'
import {Input, FormGroup, Modal} from 'reactstrap'

function OrderPopUp({showPopUp, email, setEmail, instructions, setInstructions, teamName, setTeamName, popUpMsg, closePopUp, confirmOrder, emailWarning}) {

    return (
        <Modal isOpen={showPopUp} toggle={closePopUp} backdrop={true} fade={false} centered={true} >
            <div className='order-pop-up'>
                <div className='order-pop-up-banner' >
                    <p className='order-pop-up-msg'>
                        {popUpMsg}
                    </p>
                    <FormGroup className='order-input-fields'>
                        <p className='order-input-heading'>Email</p>
                        {emailWarning && <p className='email-warning'>Please enter a valid email address</p>}

                        <Input required type='email' onChange={(event)=>{setEmail(event.target.value)}} 
                                placeholder='' value={email} className='mb-4' ></Input>

                        <p className='order-input-heading'>Team Name</p>

                        <Input type='text' onChange={(event)=>{setTeamName(event.target.value)}} 
                                placeholder='' value={teamName} className='mb-4'></Input>

                        <p className='order-input-heading'>Any Instructions?</p>
                        
                        <Input type='textarea'  onChange={(event)=>{setInstructions(event.target.value)}} 
                                placeholder='Please provide any special instructions...' value={instructions} className='mb-2 instruction-field'></Input>

                    </FormGroup>

                    <PopUpOption text={'Get Quote'} click={confirmOrder}/>
                    <PopUpOption text={'Cancel'} click={closePopUp}/>
                </div>
            </div>
        </Modal>
    );
}

export default OrderPopUp;
