import React from 'react'
import ClubbieLoader from './ClubbieLoader'
import './OverlayLoader.css'

function OverlayLoader() {
  return (
    <div className='overlay-loader'>
        <ClubbieLoader />
    </div>
  )
}

export default OverlayLoader