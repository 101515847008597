import React, {useState} from 'react'
import clubbieLogo from '../../assets/images/clubbie_logo_full.svg'
import backButton from '../../assets/images/back_button.svg'
import { AUTHENTICATION_STACK } from '../../utils/screenNames'
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import './RegisterWithEmail.css';
import { forgotPassword } from '../../lib/authActions';
import OverlayLoader from '../../components/OverlayLoader';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { appColors } from '../../utils/studioSettings';

function ForgotPasswordWithMobile() {

  const navigate = useNavigate()
  const [resetFailed, setResetFailed] = useState(false)
  const [telephone, setTelephone] = useState('')
  const [isLoading, setIsLoading] = useState(false)


  const goBack = () => {
    navigate(-1)
  }

  const resetPassword = async () => {
    function onSuccess(){
        setIsLoading(false)
        navigate(AUTHENTICATION_STACK.RESET_PASSWORD_WITH_MOBILE, {state: {telephone: `+${telephone}`}})
    }

    function onFail(){
        setIsLoading(false)
        setResetFailed(true)
    }

    if(resetFailed){
        setResetFailed(false)
    }

    setIsLoading(true)
    await forgotPassword(null, `+${telephone}`, onSuccess, onFail)

  }

  const goToLogin = () => {
    navigate(AUTHENTICATION_STACK.LOGIN_WITH_MOBILE)
  }


  return (
    <div className="app-container" >
      
      <div className='auth-top-bar'>
        <img className='back-button' src={backButton} alt="hello" onClick={goBack}/> 
        <img className="clubbie-logo" src={clubbieLogo} alt="hello"/>
      </div>

      <div className='welcome-text-box'>
        <p className="welcome-text">Reset your password</p>
        <p className="welcome-msg"> Enter the phone number you used when creating an account with Clubbie.</p>
      </div>

      <div className='button-container-email-register'>

        {resetFailed && <p className='info-warning'>Password reset failed.</p>}

        <PhoneInput
            country={'us'}
            value={telephone}
            onChange={(telephone) => setTelephone(telephone)}
            inputStyle={mobileInputStyles.inputStyle}
            buttonStyle={mobileInputStyles.buttonStyle}
            containerStyle={mobileInputStyles.containerStyle}
            dropdownStyle={mobileInputStyles.dropDownStyle}
            containerClass='mb-4'
            />

        
        <p className='go-register-msg'>
            Nevermind, I want to
          <button className='go-register-button' onClick={goToLogin}>
            Log in.
          </button>
        </p>

        <Button 
            className='register-button'
            onClick={resetPassword}>
            Reset Password   
        </Button>

        {isLoading && <OverlayLoader/>}

      </div>
  </div>
  )
}

const mobileInputStyles = {
    inputStyle: {
        width: "100%",
        backgroundColor: "transparent",
        borderRadius: "25px",
        borderColor: appColors.CLUBBIE_WHITE,
        height: "50px",
        boxShadow: 'none', 
        color: appColors.CLUBBIE_WHITE,
        fontSize: "15px"
    },

    buttonStyle: {
        backgroundColor: "transparent",
        borderBottom: 'none', 
        borderLeft: 'none',
        borderTop: 'none'
    },

    dropDownStyle: {
        backgroundColor: appColors.APP_BACKGROUND, 
        color: appColors.CLUBBIE_WHITE
    },

    containerStyle: {
        border: 'none', 
        boxShadow: 'none',
    }
}

export default ForgotPasswordWithMobile