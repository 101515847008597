import React from 'react'
import { Stage } from './Stage'
import PopUpOption from './PopUpOption'
import './SaveDesignPopUp.css'

function SaveDesignPopUp({design, selections, selectedDesign, selectedInputOptions, closePopUp, addToCart}) {

    const isComputer = window.innerWidth > 768

    return (
        <div className='save-pop-up'>
            <div className='save-pop-up-banner' >
                {isComputer && <div className='save-pop-up-stage'>
                    <Stage design={design} 
                            selections={selections} 
                            selectedDesign={selectedDesign}
                            selectedInputOptions={selectedInputOptions}/>
                </div>}
               
                <div className='mx-2 my-2 saved-design-button-container'>
                    <p className={`mb-0 saved-design-msg`}>
                        Design saved!
                    </p>
                    <PopUpOption text={'Add to cart'} click={addToCart}/>
                    <br/>
                    <PopUpOption text={'Continue designing'} click={closePopUp}/>
                </div>
                    
            </div>
        </div>
    );
}

export default SaveDesignPopUp;
