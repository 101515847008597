export const AUTHENTICATION_STACK = {
    LOGIN_OPTIONS: '/login',
    REGISTER_OPTIONS: '/register',
    REGISTER_WITH_EMAIL: '/emailRegister',
    LOGIN_WITH_EMAIL: '/emailLogin',
    REGISTER_WITH_MOBILE: '/mobileRegister',
    LOGIN_WITH_MOBILE: '/mobileLogin',
    VERIFICATION: '/verification',
    FORGOT_PASSWORD_WITH_EMAIL: '/forgotPasswordEmail',
    FORGOT_PASSWORD_WITH_MOBILE: '/forgotPasswordMobile',
    RESET_PASSWORD_WITH_EMAIL: '/resetPasswordEmail',
    RESET_PASSWORD_WITH_MOBILE: '/resetPasswordMobile',

    PRIVACY_POLICY: '/privacyPolicy',
    TERMS_AND_CONDITIONS: '/termsAndConditions'
}

export const STUDIO_STACK = {
    SELECTION_PAGE: '/selection',
    DESIGN_STUDIO: '/studio',
    CART: '/cart',
    SAVED_DESIGNS: '/myDesigns'
}