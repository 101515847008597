import React from 'react'
import './Selection.css'
import OptionSet from '../components/OptionSet'
import { Placeholder } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { optionSets } from '../utils/optionSets';
import TopButtons from '../components/TopButtons';
import { fixedTexts } from '../utils/fixedTexts';
import { FaFacebook, FaInstagram, FaTiktok, FaTwitter, FaLinkedin } from 'react-icons/fa';
import { appColors } from '../utils/studioSettings';

function Selection() {

  const navigate = useNavigate()

  const iconSize = 30

  const handleOptionClick = (screenName) => {
    navigate(screenName)
  }

  return (
    <div className='selection-page'>
        <div className='selection-top-bar'>
            <p className='selection-page-heading'>DESIGN STUDIO</p>
            <Placeholder />
        </div>
        
        <div className="options-holder">
            <OptionSet 
                optionSet={optionSets.s1}
                onOptionClick={handleOptionClick} 
            />
        </div>

        <TopButtons showSavedDesigns={false} />

        <p className="custom-design-note">
          {fixedTexts.designMsgs.customDesignA}
          <br/>
          {fixedTexts.designMsgs.customDesignB}
         </p>

         <div className='socials-icons'>
          <a href="https://www.facebook.com/Clubbie.UK/" target="_blank" rel="noopener noreferrer">
            <FaFacebook color={appColors.LIGHT_BLUE} size={iconSize} />
          </a>
          <a href="https://www.instagram.com/clubbieuk/" target="_blank" rel="noopener noreferrer">
            <FaInstagram color={appColors.LIGHT_BLUE} size={iconSize} />
          </a>
          <a href="https://www.tiktok.com/@clubbieuk" target="_blank" rel="noopener noreferrer">
            <FaTiktok color={appColors.LIGHT_BLUE} size={iconSize} />
          </a>
          <a href="https://x.com/Clubbie" target="_blank" rel="noopener noreferrer">
            <FaTwitter color={appColors.LIGHT_BLUE} size={iconSize} />
          </a>
          <a href="https://www.linkedin.com/company/clubbieltd" target="_blank" rel="noopener noreferrer">
            <FaLinkedin color={appColors.LIGHT_BLUE} size={iconSize} />
          </a>
        </div>
    </div>
  )
}

export default Selection