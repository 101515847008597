import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import { config } from "../utils/config";

const firebaseConfig = {apiKey: config.FIREBASE_API_KEY, 
                        authDomain: config.FIREBASE_AUTH_DOMAIN, 
                        projectId: config.FIREBASE_PROJECT_ID, 
                        storageBucket: config.FIREBASE_STORAGE_BUCKET, 
                        messagingSenderId: config.FIREBASE_MESSAGEING_SENDER_ID, 
                        appId: config.FIREBASE_APP_ID, 
                        measurementId: config.FIREBASE_MEASUREMENT_ID}

initializeApp(firebaseConfig);
export const auth = getAuth();

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({   
    prompt : "select_account "
});

const appleProvider = new OAuthProvider('apple.com');

export const signInWithGooglePopup = () => signInWithPopup(auth, googleProvider);
export const signInWithApplePopUp = () => signInWithPopup(auth, appleProvider)