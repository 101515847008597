import React, {useState} from 'react'
import clubbieLogo from '../../assets/images/clubbie_logo_full.svg'
import backButton from '../../assets/images/back_button.svg'
import { STUDIO_STACK, AUTHENTICATION_STACK } from '../../utils/screenNames'
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import './RegisterWithEmail.css';
import {Input} from 'reactstrap';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { isValidEmail } from '../../utils/helpers';
import { loginWithEmailPhone } from '../../lib/authActions';

function LoginWithEmail() {

  const navigate = useNavigate()
  const [loginFailed, setLoginFailed] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false);

  const [warningMsg, setWarningMsg] = useState("")

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }

  const goBack = () => {
    navigate(-1)
  }

  const loginWithEmail = async () => {
    function onSuccess (userData) {
      const {access, refresh} = userData.tokens
      window.localStorage.setItem("username", userData?.user?.profile?.fullName)
      window.localStorage.setItem("access", JSON.stringify(access))
      window.localStorage.setItem("refresh", JSON.stringify(refresh))

      navigate(STUDIO_STACK.SELECTION_PAGE)
    }

    function onFail (errMsg) {
      if(errMsg){
        setWarningMsg(errMsg)
      }
      else{
        setLoginFailed(true)
      }
    }

    if (!isValidEmail(email)) {
        setWarningMsg("Please provide a valid email address")
    }
    else if(!password){
        setWarningMsg("Please enter password")
    }
    else{
        await loginWithEmailPhone(email, null, password, onSuccess, onFail)
    }

  }

  const goToRegister = () => {
    navigate(AUTHENTICATION_STACK.REGISTER_OPTIONS)
  }

  const goToForgotPassword = () => {
    navigate(AUTHENTICATION_STACK.FORGOT_PASSWORD_WITH_EMAIL)
  }


  return (
    <div className="app-container" >
      
      <div className='auth-top-bar'>
        <img className='back-button' src={backButton} alt="hello" onClick={goBack}/> 
        <img className="clubbie-logo" src={clubbieLogo} alt="hello"/>
      </div>
      
      <div className='welcome-text-box'>
        <p className="welcome-text">Welcome back!</p>
        <p className="welcome-msg"> Log in to continue enjoying Clubbie.</p>
      </div>


      {loginFailed && <div>
                            <p className="login-failed-msg">Login failed.</p>
                        </div>}
       
        {warningMsg && <p className='info-warning'>{warningMsg}</p>}


      <div className='button-container-email-register'>

        <Input required type='email' onChange={(event)=>{setEmail(event.target.value)}} 
                placeholder='Email' value={email} className='mb-4 email-input' ></Input>

        <div className='password-input'>
          <Input
            type={showPassword ? "text" : "password"}
            name="password"
            id="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="mb-5 email-input"
          />
          <Button 
            type="button" 
            onClick={togglePasswordVisibility} 
            className="password-toggle-button"
          >
            {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          </Button>
        </div>

        <button className='mb-4 go-register-button' onClick={goToForgotPassword}>
            Forgot password?
        </button>


        <Button 
            className='register-button'
            onClick={loginWithEmail}>
            Log in   
        </Button>

        <p className='go-register-msg'>
            You don't have an account?
          <button className='go-register-button' onClick={goToRegister}>
            Register.
          </button>
        </p>

      </div>
  </div>
  )
}


export default LoginWithEmail