export const designs = {

    shirts: { 1: ['arm_807_301_1.png', "arm_2817_220_1.png", "back_2115_2273_1.png", "front_0_2278_1.png"],

            2: ['collar_1003_63_2.png', 'front_159_1942_1.png', 'sleeve_63_1410_2.png', 'sleeve_2010_1314_2.png'],

            3: ['full_28_217_1.png'],

            4: ['collar_1003_63_2.png', 'shoulder_857_278_2.png', 'shoulder_880_278_1.png', 
                'shoulder_1131_278_2.png', 'shoulder_2956_218_2.png', 'shoulder_2979_218_1.png',
                'shoulder_3230_218_2.png', 'sleeve_63_1410_2.png', 'sleeve_2010_1314_2.png'],

            5: ['collar_1003_63_1.png', 'full_0_0_1.png', 'sleeve_63_1410_1.png', 'sleeve_2010_1314_1.png'], 

            6: ['full_0_0_1.png'], 

            7: ['back_2590_1444_1.png', 'front_622_1564_1.png', 'sleeve_521_334_1.png', 'sleeve_2608_214_1.png'],

            8: ['full_0_0_1.png']

    },
    shorts: { 1: ["front_792_2378_1.png", "front_2316_2386_1.png"],
              2: ["full_0_0_1.png"],
              3: ['back_2412_2641_1.png', 'front_831_2633_1.png'],
              4: ["back_2400_2675_1.png", 'front_831_2667_1.png']
    },
    
    socks: { 1: ["back_0_3414_2.png", "front_0_1441_2.png", "piece_0_0_1.png", "strip_0_0_0.png"],
            2: ["back_0_1093_1.png", "back_0_3414_2.png", "front_0_794_1.png", "front_0_1441_2.png"],
    }
}