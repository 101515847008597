import React from 'react';
import {useDropzone} from 'react-dropzone';
import './DragDropFiles.css'

function DragDropFiles({setLogo, imageSrc=null}) {

    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            'image/*': ['.png', '.jpeg'],
        },
        maxFiles: 1,
        onDrop: files => setImageFile(files)
    });

    const setImageFile = (files) => {
        setLogo(files[0])
    }

    return (
        <div {...getRootProps({className: 'dropzone'})} >
            <input {...getInputProps()} />
            {
                imageSrc==="" && <p className='upload-image-text'>
                    select image
                </p>
            }
            {
                imageSrc!=="" && <img src={imageSrc} alt='uploaded-image' className='uploaded-image'/> 
            }
        </div>
    );
}

export default DragDropFiles